import React from "React";

const MiembroLeft = (props) => {
  return (

    <div className="row sqs-row row-reverse" id="yui_3_17_2_1_1633223673513_199">
      <div className="col sqs-col-1 span-1">
        <div
          className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
          data-block-type="21"
          id="block-yui_3_17_2_6_1459966349478_186088"
        >
          <div className="sqs-block-content">&nbsp;</div>
        </div>
      </div>
      <div className="col sqs-col-1 span-1">
        <div
          className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
          data-block-type="21"
          id="block-yui_3_17_2_3_1461699247313_71352"
        >
          <div className="sqs-block-content">&nbsp;</div>
        </div>
      </div>
      <div className="col sqs-col-4 span-4">
        {/* <div
        className="sqs-block spacer-block sqs-block-spacer"
        data-aspect-ratio="37.94076163610719"
        data-block-type="21"
        id="block-yui_3_17_2_9_1461786697557_40133"
      >
        <div
          className="sqs-block-content sqs-intrinsic"
          id="yui_3_17_2_1_1633223673513_237"
        >
          &nbsp;
        </div>
      </div> */}
        <div
          className="sqs-block html-block sqs-block-html"
          data-block-type="2"
          id="block-yui_3_17_2_6_1459966349478_159618"
        >
          <div className="sqs-block-content">
            <h2 className="text-align-right">{props.name}</h2>
            <h3 className="text-align-right">{props.title}</h3>
            <p className="text-align-right">
              {props.description}
            </p>
          </div>
        </div>
      </div>
      <div className="col sqs-col-5 span-5" id="yui_3_17_2_1_1633223673513_198">
        <div
          className="sqs-block image-block sqs-block-image sqs-text-ready"
          data-block-type="5"
          id="block-yui_3_17_2_6_1458582665748_37860"
        >
          <div
            className="sqs-block-content"
            id="yui_3_17_2_1_1633223673513_197"
          >
            <div
              className="
              image-block-outer-wrapper
              layout-caption-hidden
              design-layout-inline
            "
              data-test="image-block-inline-outer-wrapper"
              id="yui_3_17_2_1_1633223673513_196"
            >
              <figure
                className="sqs-block-image-figure intrinsic"
                id="yui_3_17_2_1_1633223673513_195"
              >
                <div
                  className="image-block-wrapper"
                  data-animation-role="image"
                  data-animation-override=""
                  id="yui_3_17_2_1_1633223673513_194"
                >
                  <div
                    className="
                    sqs-image-shape-container-element
                    has-aspect-ratio
                  "
                  >
                    <noscript
                    ><img
                        src={props.image}
                        alt="" /></noscript
                    ><img
                      className="thumb-image loaded"
                      data-component-key="idv1.i.56f0499fb09f958b0995af80"
                      data-src={props.image}
                      data-image={props.image}
                      data-image-dimensions="1000x1230"
                      data-image-focal-point="0.5,0.5"
                      alt=""
                      data-load="false"
                      data-image-id="56f0499fb09f958b0995af80"
                      data-type="image"
                      data-image-resolution="1500w"
                      src={props.image}
                    />
                  </div>
                </div>
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div className="col sqs-col-1 span-1">
        <div
          className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
          data-block-type="21"
          id="block-yui_3_17_2_6_1459966349478_189644"
        >
          <div className="sqs-block-content">&nbsp;</div>
        </div>
      </div>
    </div>

  );
};

export default MiembroLeft;
