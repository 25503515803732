import React from "React";

const Miembro = (props) => {
  return (

    <div className="row sqs-row row-normal" id="yui_3_17_2_1_1633223673513_171">
      <div className="col sqs-col-1 span-1">
        <div
          className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
          data-block-type="21"
          id="block-yui_3_17_2_6_1459966349478_166281"
        >
          <div className="sqs-block-content">&nbsp;</div>
        </div>
      </div>
      <div className="col sqs-col-5 span-5" id="yui_3_17_2_1_1633223673513_170">
        <div
          className="sqs-block image-block sqs-block-image sqs-text-ready"
          data-block-type="5"
          id="block-yui_3_17_2_6_1458582665748_40995"
        >
          <div
            className="sqs-block-content"
            id="yui_3_17_2_1_1633223673513_169"
          >
            <div
              className="
              image-block-outer-wrapper
              layout-caption-hidden
              design-layout-inline
            "
              data-test="image-block-inline-outer-wrapper"
              id="yui_3_17_2_1_1633223673513_168"
            >
              <figure
                className="sqs-block-image-figure intrinsic"
                id="yui_3_17_2_1_1633223673513_167"
              >
                <div
                  className="image-block-wrapper"
                  data-animation-role="image"
                  data-animation-override=""
                  id="yui_3_17_2_1_1633223673513_166"
                >
                  <div
                    className="
                    sqs-image-shape-container-element
                    has-aspect-ratio
                  "
                  >
                    <noscript
                    ><img
                        src={props.image}
                        alt="" /></noscript
                    ><img
                      className="thumb-image loaded"
                      data-component-key="idv1.i.56f049d6b09f958b0995b18e"
                      data-src={props.image}
                      data-image={props.image}
                      data-image-dimensions="1000x1230"
                      data-image-focal-point="0.5,0.5"
                      alt=""
                      data-load="false"
                      data-image-id="56f049d6b09f958b0995b18e"
                      data-type="image"
                      data-image-resolution="1500w"
                      src={props.image}
                    />
                  </div>
                </div>
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div className="col sqs-col-4 span-4">
        {/* <div
        className="sqs-block spacer-block sqs-block-spacer"
        data-aspect-ratio="41.325811001410436"
        data-block-type="21"
        id="block-yui_3_17_2_9_1461786697557_16278"
      >
        <div
          className="sqs-block-content sqs-intrinsic"
          id="yui_3_17_2_1_1633223673513_234"
        >
          &nbsp;
        </div>
      </div> */}
        <div
          className="sqs-block html-block sqs-block-html"
          data-block-type="2"
          id="block-yui_3_17_2_6_1459966349478_127319"
        >
          <div className="sqs-block-content">
            <h2>{props.name}</h2>
            <h3>{props.title}</h3>
            <p>
              {props.description}
            </p>
          </div>
        </div>
      </div>
      <div className="col sqs-col-1 span-1">
        <div
          className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
          data-block-type="21"
          id="block-yui_3_17_2_3_1461699247313_44947"
        >
          <div className="sqs-block-content">&nbsp;</div>
        </div>
      </div>
      <div className="col sqs-col-1 span-1">
        <div
          className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
          data-block-type="21"
          id="block-yui_3_17_2_6_1459966349478_172895"
        >
          <div className="sqs-block-content">&nbsp;</div>
        </div>
      </div>
    </div>

  );
};

export default Miembro;
