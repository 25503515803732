import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Spacer from "../components/Spacer";
import Miembro from "../components/Miembro";
import MiembroLeft from "../components/MiembroLeft";
import Michel from '../images/michel.png'
import Luis from '../images/luis-mendez.jpeg'
import Gio from '../images/gio.png'
import Mauro from '../images/mauro.png'
import Elmer from '../images/elmer.png'
import Vilma from '../images/vilma.png'
import Fernando from '../images/fernando.png'
import Gaby from '../images/gaby.png'
import Violeta from '../images/violeta.png'

// markup
const EquipoPage = () => {
  return (
    <>
      <Header></Header>
      <section
        className="Index-page-content equipo-page"
        data-content-field="main-content"
        id="yui_3_17_2_1_1633223673513_174"
      >
        <div
          className="sqs-layout sqs-grid-12 columns-12"
          data-type="page"
          data-updated-on="1462829943396"
          id="page-56e1f050859fd0fd2f519e07"
        >


          <div className="row sqs-row" id="yui_3_17_2_1_1633223673513_173">
            <div className="col sqs-col-12 span-12" id="yui_3_17_2_1_1633223673513_172">
              <h1 style={{ textAlign: 'center' }}>Equipo</h1>

              <Miembro
                name="Michel Galeano"
                title="Pastor titular"
                description="Es Colombiano, casado con Gaby, y padre de Priscilla y Sebastian.
        Obtuvo su licenciatura de ministerio cristiano y pastor del Seminario Teológico Bautista de New Orleans, y una maestría en divinidad en el Seminario Bethlehem en Minneapolis."
                image={Michel}
              ></Miembro>

              <Spacer></Spacer>


              <MiembroLeft
                name="Luis  Méndez "
                title="Pastor Asociado"
                description="Luis nació en Santiago, República Dominicana. Obtuvo sus estudios en el Instituto Teológico de Bethlehem Baptist Church, bajo la guía del pastor John Piper.  Luis es miembro de la Asociación de Consejeros Bíblicos Certificados en Estados Unidos (ACBC) y también un Life Coaching Certificado con la American Association of Christian Counselors. Luis tiene una Maestría de Consejeria del Southern Baptist Theological Seminary y ejerce parcialmente la labor de Life Coach. Luis está casado con Vilma desde 1988, junto a quien ha procreado tres hijos: Raquel, Eva y Luis Jr. "
                image={Luis}
              ></MiembroLeft>

              <Spacer></Spacer>

              <Miembro
                name="Mauricio Gutiérrez"
                title="Coordinador de los colaboradores"
                description="Mejor conocido como “Mauro”. Nació en Colombia, casado con Mariela Castañeda y es padre de 2 hijos. Mauricio tiene 3 años como miembro activo de Gracia Sobre Gracia. Graduado en educación física y masaje terapéutico. Le gustan los deportes y le apasionan las misiones. Ama al SEÑOR Y le encanta servir y cuidar de su iglesia."
                image={Mauro}
              ></Miembro>

              <Spacer></Spacer>

              <MiembroLeft
                name="Geovanys Quintino"
                title="Coordinador de Anfitriones"
                description="Nació en La Habana, Cuba, casado con Saily Torres y padre de Giancarlo y Gabriel Quintino.  Geo de profesión es técnico de CT y le gusta aprender y conocer más de Dios para hacer su voluntad."
                image={Gio}
              ></MiembroLeft>

              <Spacer></Spacer>

              <Miembro
                name="Elmer Trujillo"
                title="Coordinador del Ministerio de Adoración"
                description="Nació en Colombia (Mompox, Bol), casado con Luisa Fernanda Vargas. Elmer es Productor de eventos de profesión y al mismo tiempo es estudiante de Teología en la FUSBC ( Fundación Universitaria Seminario Bíblico de Colombia). Elmer también es el Coordinador del área de Audio y Visual."
                image={Elmer}
              ></Miembro>

              <Spacer></Spacer>


              <MiembroLeft
                name="Fernando Orochena"
                title="Coordinador de Hombres de Gracia"
                description="Nació en Nicaragua, casado con Lissellote y padre de Fernando jr, Noelis y Diego. Fernando es Médico Asistente en especialidad de emergencia de profesión y es estudiante de Maestría en Divinidad en The Southern Baptist Theological Seminary. Fernando ama enseñar y cuidar de esta manera a la Iglesia de Su Señor Jesucristo."
                image={Fernando}
              ></MiembroLeft>


              <Spacer></Spacer>


              <Miembro
                name="Gaby Galeano"
                title="Coordinadora de Mujeres de la Palabra"
                description="Es Guatemalteca, casada con Michel Galeano, madre de Priscilla y Sebastian. . Obtuvo su licenciatura de ministerio cristiano del Seminario Teológico Bautista de New Orleans, y una Maestría en Consejería con concentración en Familias y Matrimonios de la Universidad de Liberty en Virginia. Su mayor gozo es que otros conozcan de Jesús y le atesoren."
                image={Gaby}
              ></Miembro>


              <Spacer></Spacer>


              <MiembroLeft
                name="Vilma Mata de Mendez"
                title="Coordinadora de Mujeres de la Palabra"
                description="Vilma es Arquitecto, consejera Bíblica entrenada por CCEF (Christian Counseling Educational Foundation) y Faith Biblical Counseling), además de ser maestra de estudios bíblicos. Tiene un certificado de Estudio de mujeres de AACC.es dominicana, conoció al Senor en sus anos universitarios. En su Iglesia en  Rep. Dom. conoció al pastor Luis Mendez, Está casada hace 35 años con el  y tienen tres hijos. Sirvieron juntos durante once años en Minneapolis, Minnesota bajo el ministerio del pastor John Piper, donde Vilma tomó clases de Fundamentos de Teología y formó parte del Liderazgo del ministerio de mujeres en Bethlehem Baptist Church. "
                image={Vilma}
              ></MiembroLeft>
              <Spacer></Spacer>

              <Miembro
                name="Violeta Guerra"
                title="Coordinadora de Mujeres de la Palabra"
                description="Violeta ama leer, aprender y enseñar la Palabra a otras mujeres. Es arquitecta, escritora y conferencista, y posee una maestría en estudios teológicos del Southwestern Baptist Theological Seminary, una maestría en divinidades del Southern Baptist Theological Seminary, y cursa estudios doctorales en el Midwestern Baptist Theological Seminary."
                image={Violeta}
              ></Miembro>


              <Spacer></Spacer>


            </div>
          </div>
        </div>
      </section>

      <Footer></Footer>
    </>
  );
};

export default EquipoPage;
